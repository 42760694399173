@import url("../../Base.css");

.propertyContainer {
    display: flex;
    height: 100vh;
    flex-direction: column;
}

.propertyButtonContainer {
    flex:0 ;
    justify-content: center;
    display: flex;
    padding-bottom: 2vh;
}

.propertyContentContainer {
    flex:1 ;
    display: flex;
    flex-direction: column;
    color: var(--primary-text);
    font-family: var(--font-family);
    align-items: center;
    justify-content: center;
}

.propertyTitle {
    font-size: 7vw;
}

.propertyText {
    font-size: 2vw;
    padding: 2.5% 15% 0 15%
}

@media only screen and (max-width: 700px) {
    .propertyTitle {
        font-size: 15vw;
    }

    .propertyText {
        font-size: 6vw;
        padding: 10% 5% 0 5%
    }
}