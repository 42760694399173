@import url("../Base.css");

.firstSection {
    display: flex;
    height: 92.5vh;
    background-color: var(--primary-light);
    justify-content: center;
    align-items: center;
    position: relative;
}

.lottieContainer {
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.divider {
    height: 7.5vh;
    background-color: var(--contrast);
}

.firstButtonContainer {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateY(50%) translateX(-50%);
}

.propertyBackground {
    background-image: linear-gradient(70deg, #2e2b66, #140336);
    overflow: hidden;
}