@import url("../../Base.css");

.demoBackdrop {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(150deg, #8E5DF1, #5C2AC0);
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 0 5% 0 5%;
    overflow: hidden;
}

.demoContent {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
}

.demoText {
    flex: 0;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    font-family: var(--mono-font-family);
    color: var(--primary-text);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    flex-basis: 60%;
    font-size: 0;
}

.demoPhoneContainer {
    flex: 0;
    flex-basis: 40%;
    position: relative;
    display: grid;
}

.phoneCutout{
    flex: 1;
    position: absolute;
    display: block;
    max-width:100%;
    max-height:100%;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
}

.vidContainer {
    position: absolute;
    aspect-ratio: 193/402;
    max-height: 100%;
    max-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-49.8%, -45%);
}

@media only screen and (max-width: 700px) {
    .demoText {
        padding: 15%;
        text-align: center;
        flex:0;
    }
    .demoPhoneContainer {
        flex:1
    }
    .demoContent {
        flex-direction: column;
    }

    .phoneCutout{
        transform: translate(-50%, -53%);
    }

    .vidContainer {
    transform: translate(-49.8%, -53%);
    }
}